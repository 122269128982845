<template>
  <div class="device-container">
    <div class="device-heading">
      <div class="device-title">
        PAUL ONE
      </div>
      <div class="filter-container">
        <div class="device-filter">
          <div class="search-container">
            <input type="text" placeholder="Seriennummer" class="search-input" v-model="uid" @input="filterByUid()">
          </div>
          <!-- <div class="search-container">
            <input type="text" placeholder="Adresse" class="search-input" v-model="address" @input="filterByAddress()">
          </div> -->
        </div>
      </div>
    </div>
      <div class="device-datatable" style="overflow-y:auto;">
        <div class="device-table">
          <div class="table-head">
            <div class="table-row">
              <div class="table-column" v-for="field in columns" :key="field">
                <div class="column-sortable column" v-if="field.sortable"  @click="sort(field.name)">
                  <div class="column-sortable-icons">
                    <img v-if="currentSort.field !== field.name" src="/images/arrows.png" />
                    <img v-if="currentSort.field === field.name && currentSort.direction === 'asc'" src="/images/arrows_asc.png" />
                    <img v-if="currentSort.field === field.name && currentSort.direction === 'desc'" src="/images/arrows_desc.png" />
                    <span class="column-title">{{ field.text }}</span>
                  </div>
                </div>
                <div v-else class="column">
                  <div class="column-title">{{ field.text }}</div>
                </div>
              </div>
            </div>
          </div>
          <spinner v-if="loading" class="spinner"></spinner>
          <div class="table-body">
            <div class="table-row" @click="selectedDevice(device._id)" v-for="(device, index) in devicesData" :key="index">
                <span class="table-column">{{device.uid}}</span>
                <span v-if="showAddress" class="table-column">{{device.address}}</span>
                <span class="table-column">{{formatDate(device.started_at)}}</span>
                <span class="table-column">{{formatDate(device.restarted_at)}}</span>
                <span class="table-column">{{formatDate(device.updated_at)}}</span>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import { isEmpty, truncate } from 'lodash';
import Spinner from "./Spinner.vue";

export default {
  name: 'DeviceTable',
  components: {
    Spinner,
  },
  data() {
    return {
      showAddress: false,
      columns: [
        { name: 'uid', text: 'Seriennummer', sortable: true },
        // { name: 'address', text: 'Adresse', sortable: true },
        { name: 'started_at', text: 'Inbetriebnahme', sortable: true },
        { name: 'restarted_at', text: 'Letzter Neustart', sortable: true },
        { name: 'updated_at', text: 'Zuletzt Aktiv', sortable: true }
      ],
      devicesData: [],
      currentSort: {
        field: '',
        direction: ''
      },
      filterData: {
        offset: 0,
      },
      uid: null,
      address: null,
      loading: false,
    }
  },
  watch: {
    uid: function(val) {
      if(this.uid == '') {
        delete this.filterData.uid;
        this.filterByUid()
      }
    },
    address: function(val) {
      if(this.address == '') {
        delete this.filterData.address;
        this.filterByAddress();
      }
    },
  },
  computed: {
    ...mapGetters(['getData', 'getSelectedData', 'getSensors']),
    tableHead() {
      return this.columns;
    },
  },
  created() {
    this.getSensorsConfig().then(() => {
      this.loading = true;
      this.getDevicesData()
      console.log(this.getSensors);
    })
  },
  mounted() {
    const listElm = document.querySelector('.device-datatable');
    listElm.addEventListener('scroll', e => {
      if(listElm.scrollTop +listElm.clientHeight >= listElm.scrollHeight) {
        this.filterData.offset += 20;
        this.getDevices(this.filterData).then(() => {
          this.devicesData.push(...this.getData.devices);
        })
      }
    })
  },
  methods: {
    ...mapActions(['getDevices', 'getSelectedDevice', 'getSensorsConfig']),
    formatDate(data) {
      if(data) {
        return moment(data).format('DD.MM.YYYY');
      } else {
        return '';
      }
    },
    selectedDevice(id) {
      this.getSelectedDevice(id).then(() => {
        localStorage.setItem('deviceDetails', JSON.stringify({...this.getSelectedData}));
        this.$router.push(`/devices/${id}`);
      });
    },
    getDevicesData() {
      this.getDevices(this.filterData).then(() => {
      console.log(this.getData);
      this.devicesData = this.getData.devices;
      this.loading = false;
      })
    },
    filterByUid() {
      this.loading = true;
      if(this.uid) {
        this.filterData.offset = 0;
        if(this.uid.length >= 3) {
          this.filterData.uid = this.uid;
        }
      }
      this.getDevicesData();
    },
    filterByAddress() {
      this.loading = true;
      if(this.address) {
        this.filterData.offset = 0;
        if(this.address.length >= 3) {
          this.filterData.address = this.address;
        }
      }
      this.getDevicesData();
    },
    sort(name) {
      let direction = 'asc';
      if (name === this.currentSort.field && this.currentSort.direction === 'asc') {
        direction = 'desc';
      }
      this.currentSort = {
        field: name,
        direction: direction
      };
      if(this.currentSort.direction === 'desc') {
        this.filterData.sort = `-${this.currentSort.field}`
        this.filterData.offset = 0
      } else {
        this.filterData.sort = this.currentSort.field
        this.filterData.offset = 0
      }
      this.getDevicesData();
    },
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  left: 50%;
  top: 50%;
}
.device-container {
  font-family: 'Roboto', sans-serif;
  padding-top: 45px;
  margin-left: 45px;
  margin-right: 45px;
  padding-bottom: 50px;

  .device-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .device-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 478px;
      justify-content: space-between;
      font-weight: 500;
      font-size: 36px;
      color: #022259;
      text-align: left;
      font-style: normal;
    }
  }

  .device-table {
    width: 99.5%;

    .table-head, .table-body {
      width: 100%;

      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        cursor: pointer;

        .column-title {
          text-align: center;
        }
      }
    }

    .table-head {
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      border: 2px solid #EFF9FF;
      height: 50px;
      position: sticky; 
      top: 0;
      background: #ffffff;
      
      .table-row {

        .table-column {
          margin: 0 auto;
          width: 200px;

          .column-sortable {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-self: center;
            align-items: center;
            padding: 10px;

            &:hover {
              cursor: pointer;
            }

            .column-sortable-icons {
              margin: 0 auto;
              img {
                position: relative;
                top: -2px;
              }

              .column-title {
                padding-left: 10px;
              }
            }
          }

          .column {
            padding: 10px;
            
          }
        }
      }
    }

    .table-body {
      font-size: 18px;
      font-weight: 400;
      color: #022259;
      width: 100%;
      border: 2px solid #EFF9FF;

      .table-row {
        min-height: 60px;
        &:nth-child(odd) {
          background: #EFF9FF;
        }

        .table-column {
          text-align: center;
          line-height: 22px;
          width: 25%;
        }
      }

      .table-row:hover {
        background: #e2f5ff;
      }
    }
  }
  
  .device-datatable {
    height: 66vh;
    display: grid;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 35px;
  }
}

.device-filter {
    // width: 500px;
    height: 45px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input::-webkit-input-placeholder { color: #022259; }

    .search-input {
    font-size: 18px;
    color: #022259;
    background: #FFFFFF;
    border: 2px solid #022259;
    border-radius: 13px;
    box-sizing: border-box;
    padding: 14px;
    width: 100%;
    height: 100%;
  }

  input[type=text] {
    padding-left: 50px;
  }
}

.search-container {
  position: relative;
  width: 235px;
  height: 45px;
&:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  margin: auto 0;
  width: 16px;
  height: 16px;
  background: url('/images/search.png') no-repeat;
  background-size: cover;
}
  input {
    width: 100%;
  }
}

input[type="text"]::-webkit-input-placeholder {
    color: #afafaf;
}

input[type="text"]::-moz-placeholder {
    color: #afafaf;
}

@media screen and (min-width: 1400px) and (max-width: 1920px){
  template {
    width: 100%;

    .device-container {
      width: 100%;
      margin-left: 45px;
      margin-right: 45px;
    }
  }
}

@media screen and (max-width: 1400px){
  .device-heading {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1000px){
  .device-datatable {
    width: 100%;
    overflow-x: auto;
  }
}

@media screen and (min-width: 2010px){

  .device-heading {
    width: 1920px;
    margin: 0 auto;
  }

  .device-container {
    width: 1920px !important;
    margin: 0 auto !important;
  }

  .device-table {
    width: 1915px !important;
  }
}
</style>
