
import {defineComponent} from 'vue';
import DevicesTable from '../../components/DevicesTable.vue';

export default defineComponent({
  name: 'Devices',
  components: {
    'devices-table': DevicesTable
  }
});
